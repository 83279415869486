import { type I18n, type MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/core/macro";

import { CreateBookingRequestPaymentCategoryDrinks } from "@/shared/api/generated/schemas";

export const createBookingPaymentFormDrinksLabels: Record<
  Exclude<CreateBookingRequestPaymentCategoryDrinks, null>,
  MessageDescriptor
> = {
  invoice: msg`Faktura`,
  cash: msg`Kontant felles`,
  cash_individual: msg`Kontant enkeltvis`,
} as const;

export const createBookingPaymentFormDrinksOptions = (i18n: I18n) =>
  Object.values(CreateBookingRequestPaymentCategoryDrinks).map((value) => ({
    value,
    label: i18n.t(createBookingPaymentFormDrinksLabels[value]),
  }));
