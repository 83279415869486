import { Trans, useLingui } from "@lingui/react/macro";
import { Button, Group, Modal, Textarea, UnstyledButton } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconMessage, IconMessageOff } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import {
  getGetBookingsQueryKey,
  useUpdateBookingDetailedInformation,
} from "@/shared/api/generated/booking";
import { GetBookingData } from "@/shared/api/generated/schemas";

export default function UpdateAdditionalInformationModal({
  booking,
}: {
  booking: GetBookingData;
}) {
  const [opened, { open, close }] = useDisclosure(false);

  const bookingId = booking.id;

  return (
    <>
      <UnstyledButton onClick={open}>
        {booking.internalInformation || booking.externalInformation ? (
          <IconMessage />
        ) : (
          <IconMessageOff />
        )}
      </UnstyledButton>

      <Modal
        opened={opened}
        onClose={close}
        title={<Trans>Detaljer for booking #{bookingId}</Trans>}
      >
        <BookingNotesModalContent booking={booking} onClose={close} />
      </Modal>
    </>
  );
}

const additionalInformationBookingFormSchema = z.object({
  external: z.string(),
  internal: z.string(),
});

type AdditionalInformationBookingFormInitial = z.infer<
  typeof additionalInformationBookingFormSchema
>;

function BookingNotesModalContent({
  booking,
  onClose,
}: {
  booking: GetBookingData;
  onClose: () => void;
}) {
  const { t } = useLingui();
  const form = useForm<AdditionalInformationBookingFormInitial>({
    validate: zodResolver(additionalInformationBookingFormSchema),
    initialValues: {
      external: booking.externalInformation ?? "",
      internal: booking.internalInformation ?? "",
    },
  });

  const queryClient = useQueryClient();

  const { mutateAsync: updateBookingDetailedInformation } =
    useUpdateBookingDetailedInformation({
      mutation: {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getGetBookingsQueryKey(),
          });
          onClose();
        },
      },
    });

  async function handleSubmit(values: AdditionalInformationBookingFormInitial) {
    console.log(values);

    await updateBookingDetailedInformation({
      id: booking.id,
      data: {
        externalInformation: values.external,
        internalInformation: values.internal,
        updatedAt: booking.updatedAt,
      },
    });
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Textarea
        label={t`Ekstern informasjon`}
        placeholder={t`Ekstern informasjon`}
        description={t`Kommer med på informasjon ut til kunde.`}
        autosize
        minRows={6}
        {...form.getInputProps("external")}
        key={form.key("external")}
      />
      <Textarea
        label={t`Intern informasjon`}
        placeholder={t`Intern informasjon`}
        description={t`Kommer bare med på dokument til interne.`}
        autosize
        minRows={6}
        {...form.getInputProps("internal")}
        key={form.key("internal")}
      />
      <Group justify="end" gap={8} mt={8}>
        <Button variant="subtle" type="button" onClick={onClose}>
          <Trans>Avbryt</Trans>
        </Button>
        <Button type="submit">
          <Trans>Opprett</Trans>
        </Button>
      </Group>
    </form>
  );
}
