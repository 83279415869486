import { useMemo } from "react";
import { Trans, useLingui } from "@lingui/react/macro";
import {
  ActionIcon,
  Divider,
  Flex,
  Group,
  Menu,
  Switch,
  Tooltip,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { IconFileTypeCsv } from "@tabler/icons-react";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import dayjs from "dayjs";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { flatten } from "flat";
import {
  createMRTColumnHelper,
  MantineReactTable,
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ColumnSizingState,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_VisibilityState,
  useMantineReactTable,
} from "mantine-react-table";

import { useGetBookings } from "@/shared/api/generated/booking";
import { GetBookingsItem } from "@/shared/api/generated/schemas";
import { AnchorLink } from "@/shared/components/AnchorLink";
import { usePaginationSearchParamsState } from "@/shared/components/table/custom/usePaginationSearchParamsState";
import { getDefaultMRTOptions } from "@/shared/components/table/defaultMRTOptions";

import UpdateAdditionalInformationModal from "./update-booking/update-additional-information-modal";
import { bookingStatusLabels } from "./utils/status";
import { bookingVisitCategoryLabels } from "./utils/visit-category";

export default function BookingsTable() {
  const { includeCancelled } = useSearch({
    from: "/_auth-layout/bookings/",
  });

  const navigate = useNavigate();

  const { i18n, t } = useLingui();

  const { data: bookings, isLoading } = useGetBookings({
    includeProgram: true,
    includeCancelled: includeCancelled ?? false,
  });

  const ch = createMRTColumnHelper<GetBookingsItem>();

  const columns = [
    ch.accessor("id", {
      header: "#",
      Cell: ({ cell, renderedCellValue }) => (
        <AnchorLink
          to="/bookings/$bookingId"
          params={{ bookingId: cell.getValue().toString() }}
        >
          {renderedCellValue}
        </AnchorLink>
      ),
    }),
    ch.accessor(
      (row) =>
        i18n.date(row.arrival, { dateStyle: "short", timeStyle: "short" }),
      {
        id: "arrival",
        header: t`Ankomst`,
        filterVariant: "date-range",
        filterFn: (row, _columnId, filterValue) => {
          const [start, end] = filterValue as [
            Date | undefined | "",
            Date | undefined | "",
          ];

          // not set - Date
          if (!start && end) {
            return dayjs(row.original.arrival).isSameOrBefore(end, "day");
          }

          // Date - not set
          if (start && !end) {
            return dayjs(row.original.arrival).isSameOrAfter(start, "day");
          }

          // Date - Date
          if (start && end) {
            return dayjs(row.original.arrival).isBetween(
              start,
              end,
              "day",
              "[]",
            );
          }

          return true;
        },
      },
    ),
    ch.accessor("location.name", {
      header: t`Lokasjon`,
      filterVariant: "select",
    }),
    ch.accessor("group.guests.booked", {
      header: t`Antall`,
      filterVariant: "range",
    }),
    ch.accessor("customer.name", { header: t`Kunde`, filterVariant: "select" }),
    ch.accessor(
      (row) =>
        row.visitCategory
          ? i18n.t(bookingVisitCategoryLabels[row.visitCategory]) ||
            row.visitCategory
          : "-",
      {
        header: t`Type`,
        filterVariant: "select",
      },
    ),
    ch.accessor(
      (row) =>
        row.status
          ? i18n.t(bookingStatusLabels[row.status]) || row.status
          : "-",
      {
        header: t`Status`,
        filterVariant: "select",
      },
    ),
    ch.accessor(
      (row) =>
        row.guide?.firstName
          ? `${row.guide?.firstName} ${row.guide?.lastName ?? ""}`
          : "-",
      { header: t`Guide` },
    ),
    ch.display({
      id: "comment",
      header: "",
      Cell: ({ row }) => (
        <UpdateAdditionalInformationModal booking={row.original} />
      ),
      enableColumnActions: false,
      enableResizing: false,
      size: 60,
      mantineTableHeadCellProps: {
        align: "right",
      },
      mantineTableBodyCellProps: {
        align: "right",
      },
    }),
  ];

  const [columnSizing, setColumnSizing] =
    useLocalStorage<MRT_ColumnSizingState>({
      key: "table:bookings-table:column-sizing",
      defaultValue: {},
    });

  const [columnVisibility, setColumnVisibility] =
    useLocalStorage<MRT_VisibilityState>({
      key: "table:bookings-table:column-visibility",
      defaultValue: {},
    });

  const [columnOrder, setColumnOrder] = useLocalStorage<MRT_ColumnOrderState>({
    key: "table:bookings-table:column-order",
    getInitialValueInEffect: false,
    defaultValue: [],
  });

  const [columnPinning, setColumnPinning] =
    useLocalStorage<MRT_ColumnPinningState>({
      key: "table:bookings-table:column-pinning",
      defaultValue: {},
    });

  const [pagination, setPagination] = usePaginationSearchParamsState();

  const defaultMRTOptions = getDefaultMRTOptions<GetBookingsItem>();

  const table = useMantineReactTable({
    ...defaultMRTOptions,
    columns,
    data: useMemo(() => bookings?.data ?? [], [bookings?.data]),
    initialState: {
      ...defaultMRTOptions.initialState,
      showColumnFilters: true,
    },
    enableFacetedValues: true,
    renderTopToolbar: ({ table }) => (
      <>
        <Flex justify="space-between" p="md">
          <Group gap="xs">
            <MRT_GlobalFilterTextInput variant="default" mx={0} table={table} />
            <Switch
              label="Inkluder avlyste"
              checked={includeCancelled ?? false}
              onChange={(event) =>
                navigate({
                  to: ".",
                  search: (prev) => ({
                    ...prev,
                    includeCancelled: event.currentTarget.checked,
                  }),
                })
              }
            />
          </Group>

          <Group gap="xs">
            <Tooltip label={t`Eksportere gjeldende data til CSV`} withinPortal>
              <ActionIcon
                color="gray"
                size="lg"
                variant="subtle"
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
              >
                <IconFileTypeCsv />
              </ActionIcon>
            </Tooltip>
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </Group>
        </Flex>
        <Divider />
      </>
    ),
    enableColumnResizing: true,
    onColumnSizingChange: setColumnSizing,
    onColumnVisibilityChange: setColumnVisibility,
    enableColumnOrdering: true,
    onColumnOrderChange: setColumnOrder,
    enableColumnPinning: true,
    onColumnPinningChange: setColumnPinning,
    enablePagination: true,
    onPaginationChange: setPagination,
    state: {
      isLoading,
      columnSizing,
      columnVisibility,
      columnOrder,
      columnPinning,
      pagination,
    },
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => (
      <>
        <Link
          to="/bookings/$bookingId"
          params={{ bookingId: row.original.id.toString() }}
          style={{ textDecoration: "none" }}
        >
          <Menu.Item>
            <Trans>Endre</Trans>
          </Menu.Item>
        </Link>
        {/* <Menu.Item onClick={() => console.info("Delete")}>Delete</Menu.Item> */}
      </>
    ),
  });

  return <MantineReactTable table={table} />;
}

const csvConfig = mkConfig({
  filename: `bookings-${dayjs().format("DD.MM.YYYY")}.csv`,
  useKeysAsHeaders: true,
});

function handleExportRows(rows: MRT_Row<GetBookingsItem>[]) {
  const rowData = rows.map((row) => row.original);
  const flattenData = rowData.map((row) =>
    flatten<
      GetBookingsItem,
      Record<string, string | boolean | number | null | undefined>
    >(row),
  );
  const csv = generateCsv(csvConfig)(flattenData);
  download(csvConfig)(csv);
}
