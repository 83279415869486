/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Template API
 * Something something. <br><br>The spec is available at <a href='/swagger/api.json'>/swagger/api.json</a>.
 * OpenAPI spec version: 0
 */

/**
 * @nullable
 */
export type CreateBookingRequestVisitCategory = typeof CreateBookingRequestVisitCategory[keyof typeof CreateBookingRequestVisitCategory] | null;


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateBookingRequestVisitCategory = {
  event: 'event',
  online: 'online',
  company: 'company',
  school: 'school',
  kindergarten: 'kindergarten',
  compschool: 'compschool',
  cruise: 'cruise',
  hurtigruten: 'hurtigruten',
  havila: 'havila',
  tour: 'tour',
  local: 'local',
  freeentry: 'freeentry',
  conference: 'conference',
  hikinggroup: 'hikinggroup',
} as const;
