import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { scan } from "react-scan"; // must be imported before React and React DOM

import { initializeSentry } from "@/shared/utils/sentry/init";

import App from "./App";

scan({
  enabled: false,
  showToolbar: import.meta.env.DEV,
});

initializeSentry();

const rootElement = document.getElementById("root")!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
