import { Trans, useLingui } from "@lingui/react/macro";
import { Group, Paper, Select, Stack, Text } from "@mantine/core";
import { DateInput, TimeInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { useDebouncedCallback } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useBlocker } from "@tanstack/react-router";
import dayjs from "dayjs";
import { z } from "zod";

import {
  getGetBookingQueryKey,
  getGetBookingsQueryKey,
  useUpdateBookingVisitInformation,
} from "@/shared/api/generated/booking";
import { useGetCustomers } from "@/shared/api/generated/customers";
import {
  GetBookingData,
  GetBookingDataPaymentCategory,
  GetBookingDataPaymentCategoryDrinks,
  GetBookingDataVisitCategory,
  GetBookingResponse,
  GetBookingsResponse,
} from "@/shared/api/generated/schemas";
import { emptyStringToNull } from "@/shared/utils/forms/emptyStringToNull";
import { nullableInput } from "@/shared/utils/forms/nullableInput";

import { bookingPaymentFormOptions } from "../utils/payment-form";
import { bookingPaymentFormDrinksOptions } from "../utils/payment-form-drinks";
import { bookingVisitCategoryOptions } from "../utils/visit-category";

function useVisitInformationBookingFormSchema() {
  const { t } = useLingui();

  return z
    .object({
      date: nullableInput(
        z.date({ message: t`Velg en dato` }),
        t`Velg en dato`,
      ),
      timeStart: z.string(),
      timeEnd: z.string(),
      customer: nullableInput(
        z.string().min(1, t`Velg en kunde`),
        t`Velg en kunde`,
      ),
      visitCategory: z
        .nativeEnum(GetBookingDataVisitCategory)
        .nullable()
        .optional(),
      paymentForm: z
        .nativeEnum(GetBookingDataPaymentCategory)
        .nullable()
        .optional(),
      paymentFormDrinks: z
        .nativeEnum(GetBookingDataPaymentCategoryDrinks)
        .nullable()
        .optional(),
    })
    .refine(
      (data) => {
        const start = dayjs(data.timeStart, "HH:mm");
        const end = dayjs(data.timeEnd, "HH:mm");

        if (start.isValid() && end.isValid()) {
          return end.isAfter(start);
        }
        return true;
      },
      {
        message: t`Slutt må være etter start`,
        path: ["timeEnd"],
      },
    );
}

type VisitInformationBookingFormInitial = z.input<
  ReturnType<typeof useVisitInformationBookingFormSchema>
>;
type VisitInformationBookingFormSubmit = z.output<
  ReturnType<typeof useVisitInformationBookingFormSchema>
>;

export default function VisitInformation({
  booking,
}: {
  booking: GetBookingData;
}) {
  const { i18n, t } = useLingui();

  const { data: customers } = useGetCustomers();

  const debouncedSubmit = useDebouncedCallback(() => {
    form.onSubmit(handleSubmit)();
  }, 1000);

  const visitInformationBookingFormSchema =
    useVisitInformationBookingFormSchema();

  const form = useForm<
    VisitInformationBookingFormInitial,
    () => VisitInformationBookingFormSubmit
  >({
    mode: "controlled",
    validate: zodResolver(visitInformationBookingFormSchema),
    initialValues: {
      date: dayjs(booking.arrival).toDate(),
      timeStart: dayjs(booking.arrival).format("HH:mm"),
      timeEnd: dayjs(booking.departure).format("HH:mm"),
      customer: booking.customer.id.toString(),
      visitCategory: emptyStringToNull(booking.visitCategory ?? ""),
      paymentForm: emptyStringToNull(booking.paymentCategory ?? ""),
      paymentFormDrinks: emptyStringToNull(booking.paymentCategoryDrinks ?? ""),
    },
    onValuesChange: debouncedSubmit,
  });

  useBlocker({
    blockerFn: () =>
      window.confirm(
        t`Det er gjort endringer i skjemaet som ikke er lagret. Trykk "OK" for å forkaste endringene.`,
      ),
    condition: form.isDirty(),
  });

  const queryClient = useQueryClient();

  const { mutateAsync: updateBookingVisitInformation } =
    useUpdateBookingVisitInformation({
      mutation: {
        onSuccess: (data) => {
          queryClient.setQueryData<GetBookingResponse>(
            getGetBookingQueryKey(booking.id),
            data,
          );
          // Update this booking in the for GET /bookings
          queryClient.setQueriesData<GetBookingsResponse>(
            { queryKey: getGetBookingsQueryKey() },
            (old) => ({
              ...old,
              data: (old?.data ?? []).map((booking) =>
                booking.id === data.data.id ? data.data : booking,
              ),
            }),
          );
        },
      },
    });

  async function handleSubmit(values: VisitInformationBookingFormSubmit) {
    console.log(values);

    let arrival = dayjs(values.date).startOf("day");
    let departure = dayjs(values.date).endOf("day");

    if (values.timeStart) {
      const startTime = dayjs(values.timeStart, "HH:mm");
      arrival = dayjs(values.date)
        .hour(startTime.hour())
        .minute(startTime.minute());
    }

    if (values.timeEnd) {
      const endTime = dayjs(values.timeEnd, "HH:mm");
      departure = dayjs(values.date)
        .hour(endTime.hour())
        .minute(endTime.minute());
    }

    await updateBookingVisitInformation({
      id: booking.id,
      data: {
        locationId: booking.location.id,
        customerId: parseInt(values.customer),
        arrival: arrival.toISOString(),
        departure: departure.toISOString(),
        visitCategory: values.visitCategory,
        paymentCategory: values.paymentForm,
        paymentCategoryDrinks: values.paymentFormDrinks,
        updatedAt: booking.updatedAt,
      },
    });
    form.resetDirty(values);
  }

  return (
    <Paper p="xs" h={"100%"}>
      <Text size="sm" mb="sm">
        <Trans>Besøksinformasjon</Trans>
      </Text>

      <Stack gap="sm">
        <Group grow align="flex-start">
          <DateInput
            label={t`Ankomstdato`}
            placeholder={dayjs.localeData().longDateFormat("L")}
            withAsterisk
            clearable
            {...form.getInputProps("date")}
            key={form.key("date")}
          />

          <Group grow align="flex-start">
            <TimeInput
              label={t`Ankomst`}
              {...form.getInputProps("timeStart")}
              key={form.key("timeStart")}
            />
            <TimeInput
              label={t`Avreise`}
              {...form.getInputProps("timeEnd")}
              key={form.key("timeEnd")}
            />
          </Group>
        </Group>

        <Group grow align="flex-start">
          <Select
            label={t`Kunde`}
            placeholder={t`Velg kunde`}
            data={customers?.data?.map((customer) => ({
              value: customer.id.toString(),
              label: customer.name,
            }))}
            searchable
            clearable
            withAsterisk
            {...form.getInputProps("customer")}
            key={form.key("customer")}
          />
          <Select
            label={t`Besøkskategori`}
            placeholder={t`Velg besøkskategori`}
            data={bookingVisitCategoryOptions(i18n)}
            searchable
            clearable
            {...form.getInputProps("visitCategory")}
            key={form.key("visitCategory")}
          />
        </Group>

        <Group grow align="flex-start">
          <Select
            label={t`Betalingsform`}
            placeholder={t`Velg betalingsform`}
            data={bookingPaymentFormOptions(i18n)}
            searchable
            clearable
            {...form.getInputProps("paymentForm")}
            key={form.key("paymentForm")}
          />
        </Group>
        <Group grow align="flex-start">
          <Select
            label={t`Betalingsform drikke`}
            placeholder={t`Velg betalingsform drikke`}
            data={bookingPaymentFormDrinksOptions(i18n)}
            searchable
            clearable
            {...form.getInputProps("paymentFormDrinks")}
            key={form.key("paymentFormDrinks")}
          />
        </Group>
      </Stack>
    </Paper>
  );
}
