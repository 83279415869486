import { type I18n, type MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/core/macro";

import { GetBookingDataPaymentCategory } from "@/shared/api/generated/schemas";

export const bookingPaymentFormLabels: Record<
  Exclude<GetBookingDataPaymentCategory, null>,
  MessageDescriptor
> = {
  invoice: msg`Faktura`,
  cash: msg`Kontant felles`,
  cash_individual: msg`Kontant enkeltvis`,
  voucher: msg`Voucher`,
} as const;

export const bookingPaymentFormOptions = (i18n: I18n) =>
  Object.values(GetBookingDataPaymentCategory).map((value) => ({
    value,
    label: i18n.t(bookingPaymentFormLabels[value]),
  }));
