import { useMemo } from "react";
import { Trans, useLingui } from "@lingui/react/macro";
import { Anchor, Avatar, Menu, Text, Title } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";

import {
  getGetEmployeesQueryKey,
  useDeleteEmployee,
  useGetEmployees,
} from "@/shared/api/generated/employee";
import { GetEmployeesItem } from "@/shared/api/generated/schemas";
import { AnchorLink } from "@/shared/components/AnchorLink";
import { getDefaultMRTOptions } from "@/shared/components/table/defaultMRTOptions";

export default function EmployeesTable() {
  const { t } = useLingui();
  const { data, isLoading } = useGetEmployees();

  const ch = createMRTColumnHelper<GetEmployeesItem>();

  const columns = [
    ch.display({
      id: "id",
      header: "",
      Cell: ({ row }) => (
        <Avatar color="primary">
          {row.original.firstName.charAt(0)}
          {row.original.lastName?.charAt(0) ?? ""}
        </Avatar>
      ),
      size: 50,
      enableColumnActions: false,
    }),
    ch.accessor((row) => `${row.firstName} ${row.lastName ?? ""}`, {
      header: t`Navn`,
      Cell: ({ row, renderedCellValue }) => (
        <AnchorLink
          to="/employees/$employeeId"
          params={{ employeeId: row.original.id }}
        >
          {renderedCellValue}
        </AnchorLink>
      ),
    }),
    ch.accessor("email", {
      header: t`Epost`,
      Cell: ({ cell, renderedCellValue }) => (
        <Anchor href={`mailto:${cell.getValue()}`}>{renderedCellValue}</Anchor>
      ),
    }),
    ch.accessor("phoneNumber", { header: t`Telefon` }),
    ch.accessor(
      (row) =>
        row.locations?.length
          ? row.locations.map((location) => location.name).join(", ")
          : "-",
      {
        header: t`Lokasjon(er)`,
      },
    ),
  ];

  const table = useMantineReactTable({
    columns,
    data: useMemo(() => data?.data ?? [], [data?.data]),
    ...getDefaultMRTOptions<GetEmployeesItem>(),
    state: {
      isLoading,
    },
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => (
      <>
        <Link
          to="/employees/$employeeId"
          params={{ employeeId: row.original.id.toString() }}
          style={{ textDecoration: "none" }}
        >
          <Menu.Item>
            <Trans>Endre</Trans>
          </Menu.Item>
        </Link>
        <DeleteMenuItem
          id={row.original.id.toString()}
          name={`${row.original.firstName} ${row.original.lastName ?? ""}`}
        />
      </>
    ),
  });

  return <MantineReactTable table={table} />;
}

function DeleteMenuItem({ id, name }: { id: string; name: string }) {
  const { t } = useLingui();

  const queryClient = useQueryClient();

  const { mutateAsync: deleteEmployee } = useDeleteEmployee({
    mutation: {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: getGetEmployeesQueryKey() }),
    },
  });
  const openModal = () =>
    openConfirmModal({
      title: (
        <Title component="span" size="sm">
          <Trans>Slett ansatt</Trans>
        </Title>
      ),
      children: (
        <Text size="sm">
          <Trans>
            Er du sikker på at du vil slett den ansatte <b>{name}</b>? Dette kan
            ikke angres.
          </Trans>
        </Text>
      ),
      labels: { confirm: t`Slett`, cancel: t`Avbryt` },
      cancelProps: { variant: "subtle" },
      confirmProps: { color: "red" },
      onConfirm: () => deleteEmployee({ id }),
    });

  return (
    <Menu.Item color="red" onClick={() => openModal()}>
      <Trans>Slett</Trans>
    </Menu.Item>
  );
}
