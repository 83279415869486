import { type I18n, type MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/core/macro";

import { GetBookingDataVisitCategory } from "@/shared/api/generated/schemas";

export const bookingVisitCategoryLabels: Record<
  Exclude<GetBookingDataVisitCategory, null>,
  MessageDescriptor
> = {
  event: msg`Hendelse`,
  online: msg`Online`,
  company: msg`Bedrift`,
  school: msg`Skole`,
  kindergarten: msg`Barnehage`,
  compschool: msg`Videregående skole`,
  cruise: msg`Cruise`,
  hurtigruten: msg`Hurtigruten`,
  havila: msg`Havila`,
  tour: msg`Tur`,
  local: msg`Lokal`,
  freeentry: msg`Fri entré`,
  conference: msg`Konferanse`,
  hikinggroup: msg`Turgruppe`,
} as const;

export const bookingVisitCategoryOptions = (i18n: I18n) =>
  Object.values(GetBookingDataVisitCategory).map((value) => ({
    value,
    label: i18n.t(bookingVisitCategoryLabels[value]),
  }));
