/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Template API
 * Something something. <br><br>The spec is available at <a href='/swagger/api.json'>/swagger/api.json</a>.
 * OpenAPI spec version: 0
 */

export type GetBookingDataPaymentCategoryDrinks = typeof GetBookingDataPaymentCategoryDrinks[keyof typeof GetBookingDataPaymentCategoryDrinks];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetBookingDataPaymentCategoryDrinks = {
  invoice: 'invoice',
  cash: 'cash',
  cash_individual: 'cash_individual',
} as const;
