import { useNavigate, useSearch } from "@tanstack/react-router";
import { OnChangeFn } from "@tanstack/react-table";
import { MRT_PaginationState } from "mantine-react-table";

export const usePaginationSearchParamsState = () => {
  const { page, pageSize: pageSizeParam } = useSearch({
    strict: false,
  });

  // converting 1-based page to 0-based pageIndex
  const pageIndex = getPageIndex(page);
  const pageSize = pageSizeParam ?? 30;

  const navigate = useNavigate();

  const setPagination: NonNullable<OnChangeFn<MRT_PaginationState>> = (
    updaterOrValue,
  ) => {
    const resolvedPagination =
      typeof updaterOrValue === "function"
        ? updaterOrValue({
            pageIndex: pageIndex,
            pageSize: pageSize,
          })
        : updaterOrValue;

    void navigate({
      to: ".",
      search: (prev) => ({
        ...prev,
        page: getPage(resolvedPagination.pageIndex),
        pageSize: resolvedPagination.pageSize,
      }),
    });
  };

  const pagination = {
    pageIndex: pageIndex,
    pageSize: pageSize,
  };

  return [pagination, setPagination] as const;
};

function getPageIndex(page: number | undefined) {
  return (page ?? 1) - 1;
}

function getPage(pageIndex: number) {
  return pageIndex + 1;
}
