import { useMemo } from "react";
import { t } from "@lingui/core/macro";
import { Trans, useLingui } from "@lingui/react/macro";
import { Anchor, Menu, Text, Title } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";

import {
  getGetCustomersQueryKey,
  useDeleteCustomer,
  useGetCustomers,
} from "@/shared/api/generated/customers";
import { GetCustomersItem } from "@/shared/api/generated/schemas";
import { AnchorLink } from "@/shared/components/AnchorLink";
import { getDefaultMRTOptions } from "@/shared/components/table/defaultMRTOptions";
import { countryListnbNO } from "@/shared/utils/countryList";

export default function CustomersTable() {
  const { t } = useLingui();
  const { data, isLoading } = useGetCustomers();

  const ch = createMRTColumnHelper<GetCustomersItem>();

  const columns = [
    ch.accessor("name", {
      header: t`Navn`,
      Cell: ({ row, renderedCellValue }) => (
        <AnchorLink
          to="/customers/$customerId"
          params={{ customerId: row.original.id.toString() }}
        >
          {renderedCellValue}
        </AnchorLink>
      ),
    }),
    ch.accessor(
      (row) =>
        row.countryCode
          ? (countryListnbNO.find((c) => c.value === row.countryCode)?.label ??
            row.countryCode)
          : "-",
      {
        header: t`Land`,
        filterVariant: "select",
        filterFn: "equalsString",
      },
    ),
    ch.accessor("organizationNumber", { header: t`Org.num/EHF` }),
    ch.accessor("phoneNumber", { header: t`Telefon` }),
    ch.accessor("email", {
      header: t`Epost`,
      Cell: ({ cell, renderedCellValue }) => (
        <Anchor href={`mailto:${cell.getValue()}`}>{renderedCellValue}</Anchor>
      ),
    }),
  ];

  const defaultMRTOptions = getDefaultMRTOptions<GetCustomersItem>();

  const table = useMantineReactTable({
    ...defaultMRTOptions,
    columns,
    data: useMemo(() => data?.data ?? [], [data?.data]),
    state: {
      isLoading,
    },
    initialState: {
      ...defaultMRTOptions.initialState,
      showColumnFilters: true,
    },
    enableFacetedValues: true,
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => (
      <>
        <Link
          to="/customers/$customerId"
          params={{ customerId: row.original.id.toString() }}
          style={{ textDecoration: "none" }}
        >
          <Menu.Item>
            <Trans>Endre</Trans>
          </Menu.Item>
        </Link>
        <DeleteMenuItem
          id={row.original.id.toString()}
          name={row.original.name}
        />
      </>
    ),
  });

  return <MantineReactTable table={table} />;
}

function DeleteMenuItem({ id, name }: { id: string; name: string }) {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteCustomer } = useDeleteCustomer({
    mutation: {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: getGetCustomersQueryKey() }),
    },
  });

  const openModal = () =>
    openConfirmModal({
      title: (
        <Title component="span" size="sm">
          <Trans>Slett kunde</Trans>
        </Title>
      ),
      children: (
        <Text size="sm">
          <Trans>
            Å fjerne kunden <b>{name}</b> har stor innvirkning på mange deler av
            programmet, sørg for at du vet hva du gjør
          </Trans>
        </Text>
      ),
      labels: { confirm: t`Slett`, cancel: t`Avbryt` },
      cancelProps: { variant: "subtle" },
      confirmProps: { color: "red" },
      onConfirm: () => deleteCustomer({ id }),
    });

  return (
    <Menu.Item color="red" onClick={() => openModal()}>
      <Trans>Slett</Trans>
    </Menu.Item>
  );
}
