import { type I18n, type MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/core/macro";

import { CreateBookingRequestPaymentCategory } from "@/shared/api/generated/schemas";

export const createBookingPaymentFormLabels: Record<
  Exclude<CreateBookingRequestPaymentCategory, null>,
  MessageDescriptor
> = {
  invoice: msg`Faktura`,
  cash: msg`Kontant felles`,
  cash_individual: msg`Kontant enkeltvis`,
  voucher: msg`Voucher`,
} as const;

export const createBookingPaymentFormOptions = (i18n: I18n) =>
  Object.values(CreateBookingRequestPaymentCategory).map((value) => ({
    value,
    label: i18n.t(createBookingPaymentFormLabels[value]),
  }));
