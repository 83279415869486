import React, { Suspense } from "react";
import { UserButton } from "@clerk/clerk-react";
import { useLingui } from "@lingui/react/macro";
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Loader,
  Menu,
  NavLink,
  NavLinkProps,
  Paper,
  PaperProps,
  Stack,
  Tooltip,
  useMatches,
} from "@mantine/core";
import {
  IconChevronDown,
  IconCreditCard,
  IconMapPin,
  IconReceipt,
  IconStar,
  IconUsers,
  type Icon as IconType,
} from "@tabler/icons-react";
import {
  createFileRoute,
  createLink,
  CreateLinkProps,
  Link,
  LinkOptions,
  Outlet,
  redirect,
  RegisteredRouter,
} from "@tanstack/react-router";

import icon from "@/assets/icon-mn.svg";
import logo from "@/assets/logo-mn.svg";

export const Route = createFileRoute("/_auth-layout")({
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isSignedIn) {
      redirect({
        to: "/login/$",
        search: {
          redirect: location.href,
        },
        throw: true,
      });
    }
  },
  component: MainLayout,
});

export function MainLayout() {
  return (
    <Stack className="h-screen w-screen" gap={0}>
      {/* <MobileNavbar hiddenFrom="md" /> */}
      {/* <DesktopNavbar /> */}
      <Header />

      <Box style={{ overflowX: "hidden", flex: 1 }}>
        <Suspense
          fallback={
            <Center h="100%">
              <Loader />
            </Center>
          }
        >
          <Outlet />
        </Suspense>
      </Box>
    </Stack>
  );
}

type Routes = LinkOptions<RegisteredRouter>["to"];

function Header() {
  const { t } = useLingui();

  const showAuthName = useMatches({
    base: false,
    md: true,
  });

  const links: {
    link: Routes;
    label: string;
    nested?: {
      link: Routes;
      label: string;
    }[];
  }[] = [
    { link: "/bookings", label: t`Bookinger` },
    {
      link: "/products",
      label: t`Produkt`,
      nested: [
        { link: "/products", label: t`Alle produkt` },
        { link: "/products/categories", label: t`Kategorier` },
      ],
    },
    { link: "/employees", label: t`Personell` },
    { link: "/customers", label: t`Kunderegister` },
    { link: "/locations", label: t`Lokasjoner` },
  ];

  const items = links.map((link) => {
    const nestedMenuItems = link.nested?.map((item) => (
      <Menu.Item component={Link} to={item.link} key={item.link}>
        {item.label}
      </Menu.Item>
    ));

    if (nestedMenuItems) {
      return (
        <Menu
          key={link.label}
          trigger="click-hover"
          transitionProps={{ exitDuration: 0 }}
          withinPortal
        >
          <Menu.Target>
            <Link key={link.label} to={link.link}>
              {({ isActive }) => (
                <Button
                  size="sm"
                  px="xs"
                  variant={isActive ? "light" : "subtle"}
                >
                  <Center>
                    {link.label}
                    <IconChevronDown size={14} stroke={1.5} />
                  </Center>
                </Button>
              )}
            </Link>
          </Menu.Target>
          <Menu.Dropdown>{nestedMenuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <Link key={link.label} to={link.link}>
        {({ isActive }) => (
          <Button size="sm" px="xs" variant={isActive ? "light" : "subtle"}>
            {link.label}
          </Button>
        )}
      </Link>
    );
  });

  return (
    <Paper h={56} withBorder px="md">
      <Flex gap="md" h={56} justify="space-between" align="center">
        <Group>
          <Center>
            <Link to="/">
              <img src={logo} alt="Museum Nord" height={28} />
            </Link>
          </Center>
        </Group>

        <Group
          flex={1}
          justify="start"
          gap={5}
          wrap="nowrap"
          style={{ overflowX: "auto" }}
        >
          {items}
        </Group>

        <Flex align="center">
          <UserButton showName={showAuthName} />
        </Flex>
      </Flex>
    </Paper>
  );
}

// ---------- OLD CODE BELOW ----------

const NavRouterLink = createLink(
  // eslint-disable-next-line react/display-name
  React.forwardRef(
    (
      props: CreateLinkProps & NavLinkProps,
      ref: React.ForwardedRef<HTMLAnchorElement>,
    ) => {
      return (
        <NavLink
          rightSection={<></>}
          opened={true}
          {...props}
          ref={ref}
          renderRoot={Link}
        />
      );
    },
  ),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function DesktopNavbar(props: PaperProps) {
  const { t } = useLingui();
  return (
    <Paper
      withBorder
      component="nav"
      w={200}
      className="flex-shrink-0"
      radius={0}
      {...props}
    >
      <Stack h={"100%"} gap={0}>
        <Center p={16} py={32} pt={42}>
          <img src={logo} alt="Museum Nord" className="h-9" />
        </Center>

        <Divider m={0} />

        <Stack flex={1} pt={16} gap={0}>
          <NavRouterLink
            to="/bookings"
            label={t`Bookinger`}
            leftSection={<IconReceipt />}
          />
          <NavRouterLink
            to="/products"
            label={t`Produkt`}
            leftSection={<IconStar />}
          >
            <NavRouterLink
              to="/products"
              label={t`Alle produkt`}
              activeOptions={{ exact: true }}
              variant="subtle"
            />
            <NavRouterLink
              to="/products/categories"
              label={t`Kategorier`}
              variant="subtle"
            />
          </NavRouterLink>

          <NavRouterLink
            to="/employees"
            label={t`Personell`}
            leftSection={<IconUsers />}
          />

          <NavRouterLink
            to="/customers"
            label={t`Kunderegister`}
            leftSection={<IconCreditCard />}
          />

          <NavRouterLink
            to="/locations"
            label={t`Lokasjoner`}
            leftSection={<IconMapPin />}
          />
        </Stack>

        <Divider />

        <Flex p={16} align="center" bg="white">
          <UserButton
            showName
            appearance={{
              elements: {
                userButtonBox: {
                  flexFlow: "row-reverse",
                },
              },
            }}
          />
        </Flex>
      </Stack>
    </Paper>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function MobileNavbar(props: PaperProps) {
  const { t } = useLingui();
  return (
    <Paper
      withBorder
      component="nav"
      w={80}
      className="flex-shrink-0"
      {...props}
    >
      <Stack h={"100%"} gap={0}>
        <Center p={16} py={38}>
          <img src={icon} alt="Museum Nord icon" className="h-10" />
        </Center>

        <Divider />

        <Stack flex={1} gap={4}>
          <Link to="/">
            {({ isActive }) => (
              <MobileNavbarLink
                label={t`Bookinger`}
                icon={IconReceipt}
                active={isActive}
              />
            )}
          </Link>
          <Link to="/products">
            {({ isActive }) => (
              <MobileNavbarLink
                label={t`Produkt`}
                icon={IconStar}
                active={isActive}
              />
            )}
          </Link>
          <Link to="/employees">
            {({ isActive }) => (
              <MobileNavbarLink
                label={t`Personell`}
                icon={IconUsers}
                active={isActive}
              />
            )}
          </Link>
          <Link to="/login/$">
            {({ isActive }) => (
              <MobileNavbarLink
                label={t`Produkt`}
                icon={IconStar}
                active={isActive}
              />
            )}
          </Link>
          <Link to="/login/$">
            {({ isActive }) => (
              <MobileNavbarLink
                label={t`Lokasjoner`}
                icon={IconMapPin}
                active={isActive}
              />
            )}
          </Link>
        </Stack>

        <Divider />

        <Center p={16} bg="white">
          <UserButton
            appearance={{
              elements: {
                userButtonAvatarBox: {
                  width: 32,
                  height: 32,
                },
              },
            }}
          />
        </Center>
      </Stack>
    </Paper>
  );
}

function MobileNavbarLink({
  icon: Icon,
  label,
  active,
}: {
  label: string;
  icon: IconType;
  active: boolean;
}) {
  return (
    <Center>
      <Tooltip label={label} position="right">
        <ActionIcon size={60} variant={active ? "light" : "subtle"}>
          <Icon size={28} />
        </ActionIcon>
      </Tooltip>
    </Center>
  );
}
