import { Trans, useLingui } from "@lingui/react/macro";
import {
  ActionIcon,
  Box,
  Grid,
  Group,
  LoadingOverlay,
  Skeleton,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { fallback, zodValidator } from "@tanstack/zod-adapter";
import { z } from "zod";

import PDFSummaryModal from "@/features/bookings/booking-pdf-summary/pdf-summary-modal";
import AdditionalInformation from "@/features/bookings/update-booking/additional-information";
import GroupInformation from "@/features/bookings/update-booking/group-information";
import Program from "@/features/bookings/update-booking/program";
import StatusSelector from "@/features/bookings/update-booking/status-selector";
import TodaysBookings from "@/features/bookings/update-booking/todays-bookings";
import VisitInformation from "@/features/bookings/update-booking/visit-information";
import { bookingStatusLabels } from "@/features/bookings/utils/status";
import { useGetBooking } from "@/shared/api/generated/booking";

const bookingDetailsSearchSchema = z.object({
  page: fallback(z.number().optional(), undefined),
  pageSize: fallback(z.number().optional(), undefined),
});

export const Route = createFileRoute("/_auth-layout/bookings/$bookingId/")({
  component: () => <BookingDetailsPage />,
  validateSearch: zodValidator(bookingDetailsSearchSchema),
});

function BookingDetailsPage() {
  const { bookingId } = Route.useParams();

  const { t } = useLingui();

  const {
    data: booking,
    isLoading,
    isError,
  } = useGetBooking(parseInt(bookingId));

  const { i18n } = useLingui();

  return (
    <Stack p={16} mih="50%" key={bookingId}>
      <Group>
        <Group gap="sm">
          <Stack gap="xs">
            <Link
              to="/bookings/$bookingId"
              params={{ bookingId: (parseInt(bookingId) + 1).toString() }}
              style={{ display: "inline-flex" }}
            >
              <ActionIcon variant="light" size="xs">
                <IconChevronUp />
              </ActionIcon>
            </Link>

            <Link
              to="/bookings/$bookingId"
              params={{ bookingId: (parseInt(bookingId) - 1).toString() }}
              style={{ display: "inline-flex" }}
            >
              <ActionIcon component="span" variant="light" size="xs">
                <IconChevronDown />
              </ActionIcon>
            </Link>
          </Stack>
          <Title order={1} c={isError ? "red" : undefined}>
            #{bookingId} {isError ? t`Booking ikke funnet` : null}
          </Title>
          {/* <Group gap="xs">
              <ActionIcon variant="light">
              <IconChevronLeft />
              </ActionIcon>
              <ActionIcon variant="light">
              <IconChevronRight />
              </ActionIcon>
              </Group> */}
        </Group>

        {booking ? (
          <>
            <Group>
              <StatusSelector booking={booking.data} />

              <Text size="sm">{booking.data.location.name}</Text>

              <Stack gap={0}>
                <Text fz={10} c="dimmed">
                  {booking?.data.createdAt ? (
                    <>
                      <Trans>Opprettet:</Trans>{" "}
                      {i18n.date(booking.data.createdAt, {
                        dateStyle: "short",
                        timeStyle: "long",
                      })}
                    </>
                  ) : null}{" "}
                  {(!!booking.data.createdBy.first_name ||
                    !!booking.data.createdBy.last_name) && (
                    <Trans>
                      av {booking.data.createdBy.first_name}{" "}
                      {booking.data.createdBy.last_name}
                    </Trans>
                  )}
                </Text>
                <Text fz={10} c="dimmed">
                  {booking.data.updatedAt ? (
                    <>
                      <Trans>Sist endret:</Trans>{" "}
                      {i18n.date(booking.data.updatedAt, {
                        dateStyle: "short",
                        timeStyle: "long",
                      })}
                    </>
                  ) : null}{" "}
                  {(!!booking.data.updatedBy.first_name ||
                    !!booking.data.updatedBy.last_name) && (
                    <Trans>
                      av {booking.data.updatedBy.first_name}{" "}
                      {booking.data.updatedBy.last_name}
                    </Trans>
                  )}
                </Text>
              </Stack>
            </Group>

            <Group gap="sm" justify="end" ml="auto">
              {/* <Button leftSection={<IconX />} variant="outline" color="red">
                <Trans>Kanseller</Trans>
                </Button> */}
              <PDFSummaryModal booking={booking.data} type="internal" />
              <PDFSummaryModal booking={booking.data} type="external" />
            </Group>
          </>
        ) : (
          <Skeleton />
        )}
      </Group>

      {booking?.data.status === "cancelled" && (
        <Box p="xs" bg="red">
          <Text c="white" ta="center" fw="bolder" tt="uppercase">
            {i18n.t(bookingStatusLabels[booking.data.status])}
          </Text>
        </Box>
      )}

      <Box pos="relative" flex={1}>
        {booking ? (
          <>
            <Grid>
              <Grid.Col span={9}>
                <Grid>
                  <Grid.Col span={6}>
                    <VisitInformation booking={booking.data} />
                  </Grid.Col>

                  <Grid.Col span={6}>
                    <GroupInformation booking={booking.data} />
                  </Grid.Col>

                  <Grid.Col span={12}>
                    <Program booking={booking.data} />
                  </Grid.Col>
                </Grid>
              </Grid.Col>

              <Grid.Col span={3}>
                <AdditionalInformation booking={booking.data} />
              </Grid.Col>
              <Grid.Col span={12}>
                <TodaysBookings booking={booking.data} />
              </Grid.Col>
            </Grid>
          </>
        ) : (
          <LoadingOverlay visible={isLoading} />
        )}
      </Box>
    </Stack>
  );
}
