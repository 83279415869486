import { Trans } from "@lingui/react/macro";
import { notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { ErrorType } from "@/shared/api/client";

export const defaultOnErrorHandler = (
  error: ErrorType<{
    error: string;
  }>,
) => {
  console.error(error);
  notifications.show({
    title: <Trans>Feil</Trans>,
    message: error.response?.data.error ?? error.message,
    color: "red",
  });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // important as forms do not reinitialize even if they get new data
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: (error) => {
        if (error.status === 409) {
          notifications.show({
            title: <Trans>Lagringskonflikt</Trans>,
            message: (
              <Trans>
                Det er en nyere versjon av denne bookingen. Last siden på nytt
                for å gjøre endringer.
              </Trans>
            ),
            color: "red",
          });
          return;
        }
        defaultOnErrorHandler(error);
        Sentry.captureException(error, {
          extra: {
            error,
            "response.data": error.response?.data,
            message: error.message,
          },
        });
      },
    },
  },
  queryCache: new QueryCache({
    // More info on error handling: https://tkdodo.eu/blog/react-query-error-handling
    onError: (error) => {
      defaultOnErrorHandler(error);
      Sentry.captureException(error, {
        extra: {
          error,
          "response.data": error.response?.data,
          message: error.message,
        },
      });
    },
  }),
});

function ReactQueryProvider({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default ReactQueryProvider;
declare module "@tanstack/react-query" {
  interface Register {
    defaultError: ErrorType<{ error: string }>;
  }
}
