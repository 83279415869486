import { useLingui } from "@lingui/react/macro";
import { useForm, zodResolver } from "@mantine/form";
import { randomId, useDebouncedCallback } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash-es";
import { z } from "zod";

import {
  getGetCustomerbyCustomerNumberQueryKey,
  useUpdateCustomer,
} from "@/shared/api/generated/customers";
import { GetCustomerData } from "@/shared/api/generated/schemas";
import { emptyStringToNull } from "@/shared/utils/forms/emptyStringToNull";

const ContactPersonSchema = z.object({
  id: z.string(),
  name: z.string(),
});

function useFormSchema() {
  const { t } = useLingui();

  return z.object({
    name: z.string().min(1, { message: t`Velg navn` }),
    country: z.string().nullable(),
    organizationNumber: z.string(),
    email: z.union([z.literal(""), z.string().email()]),
    phone: z.string(),
    information: z.string(),

    address: z.string(),
    postCode: z.string(),
    city: z.string(),
    isInvoiceAddressTheSameAsAddress: z.boolean(),
    invoiceAddress: z.string(),
    invoicePostCode: z.string(),
    invoiceCity: z.string(),

    contactPeople: z.array(ContactPersonSchema),
  });
}

export type FormInitial = z.input<ReturnType<typeof useFormSchema>>;
type FormSubmit = z.output<ReturnType<typeof useFormSchema>>;

function useCustomerForm(customerId: string, customerData: GetCustomerData) {
  const queryClient = useQueryClient();

  const { mutateAsync: updateCustomer } = useUpdateCustomer({
    mutation: {
      onSuccess: (data) => {
        queryClient.setQueryData(
          getGetCustomerbyCustomerNumberQueryKey(parseInt(customerId)),
          data,
        );
      },
    },
  });

  async function handleSubmit(values: FormSubmit) {
    await updateCustomer({
      id: parseInt(customerId),
      data: {
        name: values.name,
        countryCode: emptyStringToNull(values.country),
        phoneNumber: emptyStringToNull(values.phone),
        email: emptyStringToNull(values.email),
        organizationNumber: emptyStringToNull(values.organizationNumber),
        locality: emptyStringToNull(values.city),
        postalCode: emptyStringToNull(values.postCode),
        addressLine1: emptyStringToNull(values.address),
        invoiceLocality: values.isInvoiceAddressTheSameAsAddress
          ? emptyStringToNull(values.city)
          : emptyStringToNull(values.invoiceCity),
        invoicePostalCode: values.isInvoiceAddressTheSameAsAddress
          ? emptyStringToNull(values.postCode)
          : emptyStringToNull(values.invoicePostCode),
        invoiceAddressLine1: values.isInvoiceAddressTheSameAsAddress
          ? emptyStringToNull(values.address)
          : emptyStringToNull(values.invoiceAddress),
        information: emptyStringToNull(values.information),
        contactPerson: values.contactPeople.length
          ? values.contactPeople.map((person) => person.name)
          : null,
      },
    });
    form.resetDirty(values);
  }

  const debouncedSubmit = useDebouncedCallback((values: FormSubmit) => {
    form.onSubmit(() => handleSubmit(values))();
  }, 1000);

  const formSchema = useFormSchema();

  const form = useForm<FormInitial, (v: FormInitial) => FormSubmit>({
    mode: "controlled",
    initialValues: {
      name: customerData.name,
      country: customerData.countryCode,
      organizationNumber: customerData.organizationNumber ?? "",
      email: customerData.email ?? "",
      phone: customerData.phoneNumber ?? "",
      information: customerData.information ?? "",

      address: customerData.addressLine1 ?? "",
      postCode: customerData.postalCode ?? "",
      city: customerData.locality ?? "",
      isInvoiceAddressTheSameAsAddress:
        getIsInvoiceAddressTheSameAsAddress(customerData),
      invoiceAddress: customerData.invoiceAddressLine1 ?? "",
      invoicePostCode: customerData.invoicePostalCode ?? "",
      invoiceCity: customerData.invoiceLocality ?? "",

      contactPeople:
        customerData.contactPerson?.map((person) => ({
          id: randomId(),
          name: person,
        })) ?? [],
    },
    onValuesChange: (values, previous) => {
      if (isEmpty(previous)) return;
      debouncedSubmit(values);
    },
    validate: zodResolver(formSchema),
  });

  return {
    form,
    handleSubmit,
  };
}

export default useCustomerForm;

function getIsInvoiceAddressTheSameAsAddress(
  customerData: GetCustomerData,
): boolean {
  return (
    customerData.invoiceAddressLine1 === customerData.addressLine1 &&
    customerData.invoicePostalCode === customerData.postalCode &&
    customerData.invoiceLocality === customerData.locality
  );
}
