import { useEffect } from "react";
import { useLingui } from "@lingui/react/macro";
import { useForm, zodResolver } from "@mantine/form";
import { useDebouncedCallback } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash-es";
import { z } from "zod";

import {
  getGetEmployeeQueryKey,
  useUpdateEmployee,
} from "@/shared/api/generated/employee";
import { GetEmployeeData } from "@/shared/api/generated/schemas";
import { emptyStringToNull } from "@/shared/utils/forms/emptyStringToNull";

function useFormSchema() {
  const { t } = useLingui();

  return z.object({
    firstName: z.string().min(1, { message: t`Velg navn` }),
    lastName: z.string(),
    email: z
      .string()
      .email()
      .min(1, { message: t`Velg e-post` }),
    phone: z.string(),
    locations: z.array(z.string().min(1, { message: t`Velg lokasjon(er)` })),
  });
}

type FormInitial = z.input<ReturnType<typeof useFormSchema>>;
type FormSubmit = z.output<ReturnType<typeof useFormSchema>>;

export default function useUpdateEmployeeForm(
  employeeId: string,
  employeeData?: GetEmployeeData,
) {
  const debouncedSubmit = useDebouncedCallback(() => {
    form.onSubmit(handleSubmit)();
  }, 1000);

  const formSchema = useFormSchema();

  const form = useForm<FormInitial, (v: FormInitial) => FormSubmit>({
    mode: "uncontrolled",
    validate: zodResolver(formSchema),
    onValuesChange: (_values, previous) => {
      // don't trigger PUT before the form is initialized
      if (isEmpty(previous)) {
        return;
      }
      debouncedSubmit();
    },
  });

  useEffect(() => {
    if (employeeData && form.initialized === false) {
      form.initialize({
        firstName: employeeData?.firstName ?? "",
        lastName: employeeData?.lastName ?? "",
        email: employeeData?.email ?? "",
        phone: employeeData?.phoneNumber ?? "",
        locations: employeeData?.locations?.map((l) => l.id) ?? [],
      });
    }
    // including form in deps causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData]);

  const queryClient = useQueryClient();

  const { mutateAsync: updateEmployee } = useUpdateEmployee({
    mutation: {
      onSuccess: (data) => {
        queryClient.setQueryData(getGetEmployeeQueryKey(employeeId), data);
      },
    },
  });

  async function handleSubmit(values: FormSubmit) {
    await updateEmployee({
      id: employeeId,
      data: {
        firstName: values.firstName,
        lastName: emptyStringToNull(values.lastName),
        email: values.email,
        phoneNumber: emptyStringToNull(values.phone),
        locations: values.locations,
      },
    });
    form.resetDirty(values);
  }

  return {
    form,
    handleSubmit,
  };
}
