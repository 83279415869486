import { Fragment, useMemo } from "react";
import { Trans, useLingui } from "@lingui/react/macro";
import { HoverCard, Paper, Stack, Text } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import dayjs from "dayjs";
import { sortBy } from "lodash-es";
import {
  createMRTColumnHelper,
  MantineReactTable,
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ColumnSizingState,
  MRT_VisibilityState,
  useMantineReactTable,
} from "mantine-react-table";

import { useGetBookings } from "@/shared/api/generated/booking";
import { useGetCategories } from "@/shared/api/generated/categories";
import {
  GetBookingData,
  GetBookingsItem,
} from "@/shared/api/generated/schemas";
import { AnchorLink } from "@/shared/components/AnchorLink";
import { usePaginationSearchParamsState } from "@/shared/components/table/custom/usePaginationSearchParamsState";
import { getDefaultMRTOptions } from "@/shared/components/table/defaultMRTOptions";
import { langugeListnbNO } from "@/shared/utils/languageList";

import { bookingVisitCategoryLabels } from "../utils/visit-category";
import UpdateAdditionalInformationModal from "./update-additional-information-modal";

export default function TodaysBookings({
  booking,
}: {
  booking: GetBookingData;
}) {
  const { i18n, t } = useLingui();

  const { data: categories } = useGetCategories();

  const { data: bookings } = useGetBookings(
    {
      from: dayjs(booking.arrival).startOf("day").toISOString(),
      to: dayjs(booking.arrival).endOf("day").toISOString(),
      locations: [booking.location.id],
      includeProgram: true,
    },
    {
      query: {
        select: (data) => sortBy(data.data, "arrival"),
      },
    },
  );

  const ch = createMRTColumnHelper<GetBookingsItem>();

  const columns = categories?.data
    ? [
        ch.accessor("id", {
          header: "#",
          Cell: ({ cell, renderedCellValue }) => (
            <AnchorLink
              to="/bookings/$bookingId"
              params={{ bookingId: cell.getValue().toString() }}
            >
              {renderedCellValue}
            </AnchorLink>
          ),
        }),
        ch.accessor((row) => i18n.date(row.arrival, { timeStyle: "short" }), {
          header: t`Ank.`,
        }),
        ch.accessor("group.guests.booked", { header: t`Ant.` }),
        ch.accessor("customer.name", {
          header: t`Kunde`,
          filterVariant: "select",
        }),
        ...categories.data.map((category) =>
          ch.display({
            id: category.id,
            header: category.name,
            Cell: ({ row }) => (
              <CategoryCell booking={row.original} category={category} />
            ),
          }),
        ),
        ch.accessor(
          (row) =>
            row.visitCategory
              ? i18n.t(bookingVisitCategoryLabels[row.visitCategory]) ||
                row.visitCategory
              : "-",
          {
            header: t`Type`,
            filterVariant: "select",
          },
        ),
        ch.accessor("group.languages", {
          header: t`Språk`,
          Cell: ({ cell }) =>
            cell
              .getValue()
              ?.map(
                (language) =>
                  langugeListnbNO.find(
                    (l) =>
                      l.value.localeCompare(language, undefined, {
                        sensitivity: "accent",
                      }) === 0,
                  )?.label,
              )
              .join(", ") ?? "-",
        }),
        ch.accessor(
          (row) =>
            row.guide
              ? `${row.guide.firstName} ${row.guide.lastName ?? ""}`
              : "-",
          {
            header: t`Guide`,
          },
        ),
        ch.display({
          id: "comment",
          enableResizing: false,
          header: "",
          Cell: ({ row }) =>
            booking.id !== row.original.id ? (
              <UpdateAdditionalInformationModal booking={row.original} />
            ) : null,
          enableColumnActions: false,
          size: 60,
          mantineTableHeadCellProps: {
            align: "right",
          },
          mantineTableBodyCellProps: {
            align: "right",
          },
        }),
      ]
    : [];

  const [columnSizing, setColumnSizing] =
    useLocalStorage<MRT_ColumnSizingState>({
      key: "table:booking-details-table:column-sizing",
      defaultValue: {},
    });

  const [columnVisibility, setColumnVisibility] =
    useLocalStorage<MRT_VisibilityState>({
      key: "table:booking-details-table:column-visibility",
      defaultValue: {},
    });

  const [columnOrder, setColumnOrder] = useLocalStorage<MRT_ColumnOrderState>({
    key: "table:booking-details-table:column-order",
    getInitialValueInEffect: false,
    defaultValue: [],
  });

  const [columnPinning, setColumnPinning] =
    useLocalStorage<MRT_ColumnPinningState>({
      key: "table:booking-details-table:column-pinning",
      defaultValue: {},
    });

  const [pagination, setPagination] = usePaginationSearchParamsState();

  const defaultMRTOptions = getDefaultMRTOptions<GetBookingsItem>();

  const table = useMantineReactTable({
    ...defaultMRTOptions,
    columns,
    data: useMemo(
      () => (bookings && categories ? bookings : []),
      [bookings, categories],
    ),
    enableColumnResizing: true,
    onColumnSizingChange: setColumnSizing,
    onColumnVisibilityChange: setColumnVisibility,
    enableColumnOrdering: true,
    onColumnOrderChange: setColumnOrder,
    enableColumnPinning: true,
    onColumnPinningChange: setColumnPinning,
    enablePagination: true,
    onPaginationChange: setPagination,
    state: {
      columnSizing,
      columnVisibility,
      columnOrder,
      columnPinning,
      pagination,
    },
    mantineTableBodyRowProps: ({ row }) => {
      if (row.original.id === booking.id) {
        return {
          style: {
            backgroundColor: "var(--mantine-primary-color-light)",
          },
        };
      }
      return {};
    },
    enableFacetedValues: true,
    mantinePaperProps: {
      shadow: "none",
      withBorder: false,
    },
  });

  return (
    <Paper>
      <Text size="sm" px="xs" pt="xs">
        <Trans>Dagens aktivitet</Trans>
        {" - "}
        {i18n.date(booking.arrival, { dateStyle: "long" })}
      </Text>

      <MantineReactTable table={table} />
    </Paper>
  );
}

const CategoryCell = ({
  booking,
  category,
}: {
  booking: GetBookingsItem;
  category: { id: string };
}) => {
  const { i18n } = useLingui();

  const productsFromThisCategory = sortBy(
    booking.program?.filter((entry) => entry.productCategoryID === category.id),
    ["start", "end"],
  );

  if (productsFromThisCategory.length === 0) {
    return "-";
  }

  return (
    <HoverCard withArrow>
      <HoverCard.Target>
        <Text size="sm" style={{ whiteSpace: "nowrap" }}>
          {productsFromThisCategory
            ?.map((product) => {
              if (product.start || product.end) {
                return `${
                  product.start
                    ? i18n.date(product.start, { timeStyle: "short" })
                    : ""
                }–${product.end ? i18n.date(product.end, { timeStyle: "short" }) : ""}`;
              }

              return product.productNameNO;
            })
            .join(", ") || "-"}
        </Text>
      </HoverCard.Target>

      <HoverCard.Dropdown p="xs">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            columnGap: 8,
            rowGap: 4,
          }}
        >
          {productsFromThisCategory?.map((product) => (
            <Fragment key={product.id}>
              <Text size="xs" ta={!product.start ? "right" : "left"}>
                {product.start
                  ? i18n.date(product.start, {
                      timeStyle: "short",
                    })
                  : ""}
                {product.start || product.end ? "–" : ""}
                {product.end
                  ? i18n.date(product.end, { timeStyle: "short" })
                  : ""}
              </Text>
              <Stack gap={0}>
                <Text size="xs">{product.productNameNO}</Text>
                <Text size="xs" c="dimmed">
                  {product.comment}
                </Text>
              </Stack>
            </Fragment>
          ))}
        </div>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
