import { useMemo } from "react";
import { Trans, useLingui } from "@lingui/react/macro";
import { ActionIcon, Box, Menu, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconDots } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  createMRTColumnHelper,
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";

import {
  getGetProductsQueryKey,
  useDeleteProduct,
  useGetProducts,
} from "@/shared/api/generated/products";
import { GetProductData } from "@/shared/api/generated/schemas";
import { getDefaultMRTOptions } from "@/shared/components/table/defaultMRTOptions";

import UpdateProductModal from "./update-product/update-product-modal";

export default function ProductsTable() {
  const { i18n, t } = useLingui();

  const { data } = useGetProducts();

  const ch = createMRTColumnHelper<GetProductData>();

  const columns = [
    ch.accessor("nameNO", { header: t`Navn (norsk)` }),
    ch.accessor("nameEN", { header: t`Navn (engelsk)` }),
    ch.accessor("location.name", {
      header: t`Lokasjon`,
      filterVariant: "select",
    }),
    ch.accessor("category.name", {
      header: t`Kategori`,
      filterVariant: "select",
    }),
    ch.accessor(
      (row) =>
        row.price
          ? i18n.number(row.price, { style: "currency", currency: "NOK" })
          : "-",
      {
        header: t`Pris`,
      },
    ),
    ch.accessor(
      (row) =>
        row.duration
          ? i18n.date(
              dayjs().startOf("day").set("minutes", row.duration).toDate(),
              {
                timeStyle: "short",
              },
            )
          : "-",
      {
        header: t`Varighet`,
      },
    ),
    ch.display({
      id: "actions",
      header: "",
      Cell: ProductsActions,
      enableColumnActions: false,
      size: 70,
    }),
  ];

  const table = useMantineReactTable({
    columns,
    data: useMemo(() => data?.data ?? [], [data?.data]),
    ...getDefaultMRTOptions<GetProductData>(),
    enableFacetedValues: true,
  });

  return <MantineReactTable table={table} />;
}

const ProductsActions: MRT_ColumnDef<GetProductData>["Cell"] = ({ row }) => {
  const [isUpdateModalOpen, updateModal] = useDisclosure(false);

  return (
    <Box style={{ textAlign: "right" }}>
      <Menu position="bottom-end">
        <Menu.Target>
          <ActionIcon variant="subtle" color="gray">
            <IconDots />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={() => updateModal.open()}>
            <Trans>Endre</Trans>
          </Menu.Item>
          <DeleteMenuItem id={row.original.id} name={row.original.nameNO} />
        </Menu.Dropdown>
      </Menu>

      <UpdateProductModal
        product={row.original}
        opened={isUpdateModalOpen}
        close={updateModal.close}
      />
    </Box>
  );
};

function DeleteMenuItem({ id, name }: { id: string; name: string }) {
  const { t } = useLingui();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteProduct } = useDeleteProduct({
    mutation: {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: getGetProductsQueryKey() }),
    },
  });

  const openModal = () =>
    openConfirmModal({
      title: (
        <Title component="span" size="sm">
          <Trans>Slett produkt</Trans>
        </Title>
      ),
      children: (
        <Text size="sm">
          <Trans>
            Er du sikker på at du vil fjerne <b>{name}</b> produktet?
          </Trans>
        </Text>
      ),
      labels: { confirm: t`Slett`, cancel: t`Avbryt` },
      cancelProps: { variant: "subtle" },
      confirmProps: { color: "red" },
      onConfirm: () => deleteProduct({ id }),
    });

  return (
    <Menu.Item color="red" onClick={() => openModal()}>
      <Trans>Slett</Trans>
    </Menu.Item>
  );
}
