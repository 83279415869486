import { useLingui } from "@lingui/react/macro";
import { z } from "zod";

import { nullableInput } from "@/shared/utils/forms/nullableInput";

function useNewProductFormSchema() {
  const { t } = useLingui();

  const defaultSchema = z.object({
    locationId: nullableInput(
      z.string().min(1, { message: t`Velg en lokasjon` }),
    ),
    nameNorwegian: z.string().min(1, { message: t`Velg navn` }),
    nameEnglish: z.string().min(1, { message: t`Velg navn` }),
    categoryId: nullableInput(z.string().min(1, { message: t`Velg kategori` })),
  });

  const withPriceSchema = z.object({
    withPrice: z.literal(true),
    price: z.number().int().positive(),
  });

  const withoutPriceSchema = z.object({
    withPrice: z.literal(false),
    price: z.number(),
  });

  const priceSchema = z.discriminatedUnion("withPrice", [
    withPriceSchema,
    withoutPriceSchema,
  ]);

  const withDurationSchema = z.object({
    withDuration: z.literal(true),
    duration: z.string().min(1, { message: t`Velg varighet` }),
  });

  const withoutDurationSchema = z.object({
    withDuration: z.literal(false),
    duration: z.string(),
  });

  // Inspiration and better explanation: https://github.com/colinhacks/zod/discussions/2099#discussioncomment-6209674
  const durationSchema = z.discriminatedUnion("withDuration", [
    withDurationSchema,
    withoutDurationSchema,
  ]);

  const newProductFormSchema = defaultSchema
    .and(priceSchema)
    .and(durationSchema);

  return newProductFormSchema;
}

type NewProductFormInitial = z.input<
  ReturnType<typeof useNewProductFormSchema>
>;
type NewProductFormSubmit = z.output<
  ReturnType<typeof useNewProductFormSchema>
>;

export {
  useNewProductFormSchema as useGetNewProductFormSchema,
  type NewProductFormInitial,
  type NewProductFormSubmit,
};
