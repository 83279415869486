import { useMemo } from "react";
import { Trans, useLingui } from "@lingui/react/macro";
import { ActionIcon, Box, Menu } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconDots } from "@tabler/icons-react";
import {
  createMRTColumnHelper,
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";

import { useGetLocations } from "@/shared/api/generated/locations";
import { GetLocationsItem } from "@/shared/api/generated/schemas";
import { getDefaultMRTOptions } from "@/shared/components/table/defaultMRTOptions";

import DeleteLocationMenuItem from "./delete-location";
import UpdateLocationModal from "./update-location/update-location-modal";

export default function LocationsTable() {
  const { t } = useLingui();

  const ch = createMRTColumnHelper<GetLocationsItem>();

  const { data, isLoading } = useGetLocations();

  const columns = [
    ch.accessor("name", { header: t`Navn` }),
    ch.display({
      id: "actions",
      header: "",
      Cell: LocationsActions,
      enableColumnActions: false,
      size: 70,
    }),
  ];

  const table = useMantineReactTable({
    columns,
    state: {
      isLoading,
    },
    data: useMemo(() => data?.data ?? [], [data?.data]),
    ...getDefaultMRTOptions<GetLocationsItem>(),
  });

  return <MantineReactTable table={table} />;
}

const LocationsActions: MRT_ColumnDef<GetLocationsItem>["Cell"] = ({ row }) => {
  const [isUpdateModalOpen, updateModal] = useDisclosure(false);

  return (
    <Box style={{ textAlign: "right" }}>
      <Menu position="bottom-end">
        <Menu.Target>
          <ActionIcon variant="subtle" color="gray">
            <IconDots />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={() => updateModal.open()}>
            <Trans>Endre</Trans>
          </Menu.Item>
          <DeleteLocationMenuItem
            id={row.original.id}
            name={row.original.name}
          />
        </Menu.Dropdown>
      </Menu>

      <UpdateLocationModal
        location={row.original}
        opened={isUpdateModalOpen}
        close={updateModal.close}
      />
    </Box>
  );
};
